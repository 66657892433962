@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body {
    background: linear-gradient(112.1deg, rgb(32, 38, 57) 11.4%, rgb(63, 76, 119) 70.2%);
    color: white;
    font-family: "Montserrat", sans-serif !important;
}

.bit-h {
    height: 50px;
}
.border-s {
    border: 1px solid rgb(255, 255, 255);
    border-radius: 20px;
}

.border-t {
    border: 1px solid rgb(255, 255, 255);
    border-radius: 20px;
    border-width: 2px;
}

.table > :not(caption) > * > * {
    background-color: unset;
    color: white;
    padding: 20px 30px;
}

.card-d {
    background: linear-gradient(90deg, #074f87 0%, #5869ad 100%);
    border-radius: 10px;
    padding: 30px 34px;
}

.btn-wallet {
    background-color: #f7931a;
    width: 100%;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: white;
    padding: 9px 20px;
}

.btn-wallet:hover {
    background-color: #1c2442;
}

.input-d {
    width: 100%;
    background: unset;
    border: 1px solid white;
    padding: 10px;
    border-radius: 10px;
    color: white;
}

.bit-h {
    font-weight: 800;
    font-size: 48px;
    line-height: 59px;
    letter-spacing: -0.017em;
}
.bit-p {
    font-weight: 500;
    font-size: 17px;
    line-height: 30px;
}

.align-item-center {
    align-items: center;
}

::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */
    color: white;
}
:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: white;
    opacity: 1;
}
::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: white;
    opacity: 1;
}
:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: white;
}
::-ms-input-placeholder {
    /* Microsoft Edge */
    color: white;
}

::placeholder {
    /* Most modern browsers support this now. */
    color: white;
}

.tb-para {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
}
.font-23 {
    font-weight: 600;
    font-size: 23px;
    line-height: 27px;
}
.font-16 {
    font-size: 16px;
    line-height: 20px;
}

.font-w-700 {
    font-weight: 700;
}
.overflow-y-hidden {
    overflow-y: hidden;;
}

.svg-h {
    height: 200px; 
    width: 100%;
}
.footer-shadow {
    box-shadow: inset 0 0.5em 1.5em rgba(0, 0, 0, .1), inset 0 0.125em 0.5em rgba(0, 0, 0, .15);
}

@media (max-width: 768px) { 
    .bit-h {
        font-size:25px;
        line-height: 29px;
    }
}